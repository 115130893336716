<template>
  <v-app class="background">
    <Topnav />
    <Sidenav />
    <!-- <ImportSurveyComponent /> -->
    <!-- <DropZone /> -->
    <v-row>
      <v-col>
        <div class="text-center" style="margin: 10%">
          <vue2Dropzone
            ref="dodosoDropZone"
            id="dropzone"
            :options="dropzoneOptions"
            @vdropzone-complete="afterUploadComplete"
          />
          <v-btn class="my-3 mx-1 white--text" elevation="1" color="red"  large @click="fileRemoveHandler()"> REMOVE FILE </v-btn>
          <v-btn class="my-3 mx-1" elevation="1" color="primary" large @click="fileImportHandler()"> SUBMIT </v-btn>
        </div>
        <div></div>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import Topnav from "@/components/navigators/Topnav.vue";
import Sidenav from "@/components/navigators/Sidenav.vue";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";


export default {
  name: "ImportSurveyView",
  data: function () {
    return {
      dropzoneOptions: {
        // url: "https://httpbin.org/post",
        thumbnailWidth: 150,
        maxFilesize: 10,
        dictDefaultMessage: "Drop a file here to upload"
        // headers: { "My-Awesome-Header": "header value" },
      },
    };
  },
  components: {
    Topnav,
    Sidenav,
    vue2Dropzone,
    // ImportSurveyComponent,
    // DropZone
  },

  methods: {
    afterUploadComplete: async function (response) {
      console.log("The response is",response.type); // status == 'success' or 'error'  //upload.filename      //type
      if(response.status == 'success'){
        this.$refs.dodosoDropZone.removeAllFiles();
      }
    },
    fileImportHandler: async function (){

    },
    fileRemoveHandler: async function () {
      this.$refs.dodosoDropZone.removeAllFiles();
    }
  },
};
</script>
<style scoped>
.background {
  /* background-image: url("../../assets/images/background1.png"); */
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
